
<section class="login_sign_wrapper">
    <div class="container">
        <div class="login-registration">
            <h3 class="log-header">Sign in</h3>
            <form [formGroup]="emailLoginForm" (ngSubmit)="emailLogin(emailLoginForm.value)">
                <div class="form-group">
                    <input type="email" formControlName="email" class="form-control" placeholder="Email address">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">
                          <div class="error-message" *ngIf="emailLoginForm.get('email').hasError(error.type) && 
                            (emailLoginForm.get('email').dirty || emailLoginForm.get('email').touched)">
                            {{ error.message }}
                          </div>
                        </ng-container>
                      </div>
                </div>
                <div class="form-group">
                    <input type="password"  formControlName="password" class="form-control" placeholder="Password">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.password">
                          <div class="error-message" *ngIf="emailLoginForm.get('password').hasError(error.type) && 
                            (emailLoginForm.get('password').dirty || emailLoginForm.get('password').touched)">
                            {{ error.message }}
                          </div>
                        </ng-container>
                      </div>
                </div>
                <div class="form-group forgotPass">
                    <a [routerLink]="['/forget-password']"> Forgot password?</a>
                </div>
                <button type="submit" class="btn SubmitBtn" block [disabled]="!emailLoginForm.valid"
                [ngClass]="emailLoginForm.valid ? 'loginbtn' : 'loginbtn disable-lgn-btn'">Login</button>
                
                <div class="text-center error-message">{{apiErrorMessage}}</div>
            </form>

            <div class="signup_process">
                <div class="or-group">
                    <hr>
                    <p class="or">Or</p>
                </div>
                <div class="log-action">
                    <button (click)="signInWithFB()" class="btn btnfb"><i class="fa fa-facebook" aria-hidden="true"></i></button>
                    <button (click)="signInWithGoogle()" class="btn btngoogle"><i class="fa fa-google" aria-hidden="true"></i></button>
                </div>
                <p class="signup_question"> Don't have an account yet? <a [routerLink]="['/signup']"
                         >Sign up</a> </p>
            </div>
        </div>
    </div>
</section>
<!--putngxspinner-->
