
<!-- <ngx-paypal 
  [config]="payPalConfig">
</ngx-paypal> -->
<!-- <ion-header *ngIf="page != 'boost'">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon start name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{pageTitle}}</ion-title>
  </ion-toolbar>
</ion-header> -->


<button type="button" class="close" (click)="closePaypalModal()" data-dismiss="modal">&times;</button>
<br/>
<br/>
<ng-container *ngIf="!showPaymentCard">
  <ng-container *ngIf="page == 'boost'">
    <div>
<!--  
        <button type="button" (click)="close()">
          close
        </button> -->
     
    </div>
    <div class="boots-back-image">
      <img src="assets/images/subscribe1.png">
    </div>
    <div>
      <h5 class="boost-title">Attract 10 X more buyers</h5>
      <div class="boost-txt">
        Feature your listing at the top of all listings at a discounted rate.
      </div>
    </div>
    <div class="boost-style">
        <div class="boost-group" *ngFor="let prdt of product" [ngClass]="{'boost-selected': isBoostSelected(prdt)}" (click)="selectNewBoost(prdt)">
          <button class="bestBtn btn" *ngIf="prdt.id == 'boost_monthly'">Best Value</button>
          <div>{{prdt.title}}</div>
          <div>${{prdt.price}}</div>
        </div>
    </div>
    <div class="btn-container">
      <div class="btn-center">
        <button class="btn featureBtn" (click)="checkoutFeature(selectedBoost)">Feature Now</button>
        <!-- <br/>
        <br/> -->
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="page != 'boost'">
    <div class="discount">
      <ion-icon name="md-flash"></ion-icon>
      <h4>Heavy discount on reactivation</h4>
    </div>
    <div class="fetAdd">
      <h3>Feature listing <span>See Example</span></h3>
      <div class="checkList">
        <ion-icon name="checkmark"></ion-icon>
        Reactivate your product and sale it again
      </div>
    </div>
    <div>
      
      <div *ngFor="let prdt of product" class="buyBtns">
        <button class="btn reactivateBtn" (click)="checkoutReactivate(prdt)">Buy {{prdt.title}} ${{prdt.price}} <span></span></button>
      </div>
    </div>
  </ng-container>
</ng-container>

  <!-- <ng-payment-card></ng-payment-card> -->
 

<!-- <ng-payment-card
  [ccNumMissingTxt]='Please provide card number'
  [cardExpiredTxt]='Payment card has expired'>
</ng-payment-card> -->
 

<!-- <ng-payment-card
  (formSaved)="processPayment(cc)">
</ng-payment-card>  -->

<!-- <form [formGroup]="cardForm" (ngSubmit)="onSubmit()" novalidate>
  <input id="cc-number" formControlName="creditCard" name="creditCard" type="tel" autocomplete="cc-number" ccNumber>
  <input id="cc-holder-name" formControlName="creditCardHolderName" name="creditCardHolderName" type="Text">

  <input id="cc-exp-date" formControlName="expirationDate" name="expirationDate" type="tel" autocomplete="cc-exp" ccExp>
  <input id="cc-cvc" formControlName="cvc" name="cvc" type="tel" autocomplete="off" ccCvc>
  <button type="submit">Submit</button>
</form> -->


 <!-- credit card info-->
 <!-- <ng-container [hidden]="!showPaymentCard"> -->
 <div [hidden]="!showPaymentCard"  id="nav-tab-card" class="tab-pane fade show active">

  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
    <div class="form-row">
      <!-- <label for="card-info"><span class="card-info">Card Info</span><span class="card-expiry">Expiry Date CVC</span></label> -->
      <label for="card-info text-center">Card Info</label>

      <!-- <div id="card-info" #cardInfo></div> -->

      <div id="card-info" #cardInfo></div>
      <div id="card-info" #cardInfo></div>
      <div id="card-info" #cardInfo></div>
  
      <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
    </div>
  
    <button *ngIf="!submitted" type="submit featureBtn">Pay {{selectedBoost.price}} Cents</button>
    <div *ngIf="submitted" >Please wait..</div>
  </form>

   <!-- <h5 class="text-center">Pay ${{selectedBoost.price}}.00</h5> -->
  <!-- <form role="form" [formGroup]="cardForm" (ngSubmit)="onSubmit('test')" >
    <div class="form-group">
      <label for="username">Full name (on the card)</label>
      <input type="text" formControlName="creditCardHolderName" name="creditCardHolderName" type="Text" placeholder="Name on card" required class="form-control">
    </div>
    <div class="form-group">
      <label for="cardNumber">Card number</label>
      <div class="input-group">
        <input type="text" formControlName="creditCard" name="creditCard" type="tel" autocomplete="cc-number" ccNumber placeholder="Your card number" class="form-control" required>
        <div class="input-group-append">
          <span class="input-group-text text-muted">
                                      <i class="fa fa-cc-visa mx-1"></i>
                                      <i class="fa fa-cc-amex mx-1"></i>
                                      <i class="fa fa-cc-mastercard mx-1"></i>
                                  </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <div class="form-group">
          <label><span class="hidden-xs">Expiration</span></label>
          <div class="input-group">
            <input type="number" placeholder="MM/YYYY"  formControlName="expirationDate" name="expirationDate" type="tel" autocomplete="cc-exp" ccExp class="form-control" required>
           
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group mb-4">
          <label data-toggle="tooltip" title="Three-digits code on the back of your card">CVV
                                      <i class="fa fa-question-circle"></i>
                                  </label>
          <input type="text" required class="form-control" formControlName="cvc" name="cvc" type="tel" autocomplete="off" ccCvc maxlength="4" minlength="3">
        </div>
      </div>



    </div>
    <div class="buttons-grm text-center">

    <button type="submit" class="btn featureBtn"> Confirm  </button>
  </div>
  </form> -->



</div>
<!-- </ng-container> -->
<!-- End -->

<div class="modal" id="boost-alert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h6>Please select a boost before continuing.</h6>
      </div>
      <div class="text-center">
        <button type="button" (click)="closeMe('boost-alert');" class="btn" data-dismiss="modal">Okay</button>
      </div>
    </div>
  </div>
</div>