

<section class="login_sign_wrapper">
    <div class="container">
        <div class="login-registration">
            <h3 class="log-header">Forget Password</h3>
            <form *ngIf="askOTP" [formGroup]="forgetPasswordForm" (ngSubmit)="forgetPassword(forgetPasswordForm.value)">
                <p class="sign-title">Provide your email</p>
                <p class="sign-sub-title">We will send a confirmation code to your email.</p>
                <div class="form-group">
                    <input type="email" formControlName="email" class="form-control" placeholder="Email address">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">
                            <div class="error-message" *ngIf="forgetPasswordForm.get('email').hasError(error.type) && 
                            (forgetPasswordForm.get('email').dirty || forgetPasswordForm.get('email').touched)">
                                {{ error.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- <button type="submit" class="btn SubmitBtn">Submit</button> -->

                <button class="btn SubmitBtn" block [disabled]="!forgetPasswordForm.valid"
                    [ngClass]="forgetPasswordForm.valid ? 'loginbtn' : 'loginbtn disable-lgn-btn'">Get confirmation
                    code</button>
            </form>

            <form *ngIf="checkOTP" [formGroup]="confirmCodeForm"
                (ngSubmit)="checkConfirmationCode(confirmCodeForm.value)">
                <div class="form-group">
                    <p class="sign-sub-title">Please enter the confirmation code sent to your email</p>
                    <input type="text" formControlName="code" class="form-control" placeholder="Enter code">

                </div>

                <button class="btn SubmitBtn" block [disabled]="!confirmCodeForm.valid"
                    [ngClass]="confirmCodeForm.valid ? 'loginbtn' : 'loginbtn disable-lgn-btn'">Submit confirmation
                    Code</button>
            </form>
            <form *ngIf="enterPassword" [formGroup]="newPasswordForm" (ngSubmit)="newPassword(newPasswordForm.value)">
                <div class="form-group">
                    <input type="password" formControlName="newPassword" class="form-control" placeholder="Enter new password">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.code">
                            <div class="error-message" *ngIf="newPasswordForm.get('newPassword').hasError(error.type) && 
                              (newPasswordForm.get('newPassword').dirty || newPasswordForm.get('newPassword').touched)">
                                {{ error.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Confirm password">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.password">
                            <div class="error-message" *ngIf="newPasswordForm.get('confirmPassword').hasError(error.type) && 
                              (newPasswordForm.get('confirmPassword').dirty || newPasswordForm.get('confirmPassword').touched)">
                                {{ error.message }}
                            </div>
                        </ng-container>
                        <div class="error-message"
                            *ngIf="!newPasswordForm.get('confirmPassword').errors && newPasswordForm.hasError('passwordNotMatch') && (newPasswordForm.get('confirmPassword').dirty || newPasswordForm.get('confirmPassword').touched)">
                            Password and Confirm Password fields are not matching
                        </div>
                    </div>
                </div>

                <button class="btn SubmitBtn" block [disabled]="!newPasswordForm.valid"
                    [ngClass]="newPasswordForm.valid ? 'loginbtn' : 'loginbtn disable-lgn-btn'">Change Password</button>
            </form>

        </div>
    </div>
</section>
<!--putngxspinner-->
