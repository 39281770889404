<section class="left-nav">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-sm-12 ">
                <div class="nav-menu-lay bg-fresh">
                    <ul class="nav flex_nav-item">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/user/edit-profile']" routerLinkActive="active" >Edit Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/product/add-product']" [queryParams]="productParams" routerLinkActive="active" >Add Product</a>
                        </li>
                        <!-- uma uncomment -->
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/product/add-product']" [queryParams]="commercialParams" routerLinkActive="active" >Add Commercial Product</a>
                        </li>
                        <!-- uma comment -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/product/add-commercial-product']" [queryParams]="commercialParams" routerLinkActive="active" >Add Commercial Product</a>
                        </li> -->

                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/product/my-product-listing/favourites']" routerLinkActive="active" >My Listing</a>
                        </li>
    
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/chat/chatter']" routerLinkActive="active" >Chatter</a>
                        </li>
    
                        <!-- <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/user/all-reviews']" routerLinkActive="active" >All Reviews</a>
                        </li> -->

                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/user/all-reviews',{task:'myReviews'}]" routerLinkActive="active" >All Reviews</a>
                        </li>
    
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/user/my-block-list',{task:'myBlockList'}]" routerLinkActive="active" >Block List</a>
                        </li>
    
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/'+baseRoute+'/user/profile-details',{task:'notificationSettings'}]" routerLinkActive="active" >Notification Settings</a>
                        </li>
    
                        <!-- uma uncomment -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/logout']">Logout</a>
                        </li> -->
     
                    </ul>
                </div>
            </div>
            <div class="col-xl-9 col-lg-8 col-sm-12">
                <div class="bg-fresh">
                <ng-content></ng-content>
            </div>
            </div>
        </div>
    </div>
    </section>