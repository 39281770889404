

<section class="login_sign_wrapper">
    <div class="container">
        <div class="login-registration">
            <h3 class="log-header">Sign up</h3>
            <form [formGroup]="signupForm" (ngSubmit)="userSignup(signupForm.value)">
                <div class="form-group">
                    <input type="email" formControlName="email" class="form-control" placeholder="Email address"> 
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.email">
                            <div class="error-message" *ngIf="signupForm.get('email').hasError(error.type) && 
                                    (signupForm.get('email').dirty || signupForm.get('email').touched)">
                                {{ error.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" placeholder="Password">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.password">
                            <div class="error-message"
                                *ngIf="signupForm.get('password').hasError(error.type) && (signupForm.get('password').dirty || signupForm.get('password').touched)">
                                {{ error.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" formControlName="confirmpassword" class="form-control" placeholder="Confirm password">
                    <div class="error-messages">
                        <ng-container *ngFor="let error of error_messages.confirmpassword">
                            <div class="error-message"
                                *ngIf="signupForm.get('confirmpassword').hasError(error.type) && (signupForm.get('confirmpassword').dirty || signupForm.get('confirmpassword').touched)">
                                {{ error.message }}
                            </div>
                        </ng-container>
                        <div class="error-message"
                            *ngIf="!signupForm.get('confirmpassword').errors && signupForm.hasError('passwordNotMatch') && (signupForm.get('confirmpassword').dirty || signupForm.get('confirmpassword').touched)">
                            Password and Confirm Password fields should match
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group forgotPass">
                    <a [routerLink]="['/forget-password']"> Forgot password?</a>
                </div> -->
                <!-- <button type="submit" class="btn SubmitBtn">Submit</button> -->
                <button class="btn SubmitBtn" type="submit" ion-button [ngClass]="signupForm.valid ? 'loginbtn' : 'loginbtn disable-lgn-btn'"
                [disabled]="!signupForm.valid">Sign Up</button>
            </form>

            <div class="signup_process">
                <div class="or-group">
                    <hr>
                    <p class="or">Or</p>
                </div>
                <div class="log-action">
                    <button (click)="signInWithFB()" class="btn btnfb"><i class="fa fa-facebook" aria-hidden="true"></i></button>
                    <button (click)="signInWithGoogle()" class="btn btngoogle"><i class="fa fa-google" aria-hidden="true"></i></button>
                </div>
                <p class="signup_question"> Already have an account? <a [routerLink]="['/signin']"
                         >Sign in</a> </p>
            </div>
        </div>
    </div>
</section>

