

<section class="pro-list_wrapper">
    <div class="container">
        <div class="page-nav">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Product Categories</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Cars</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12 leftPannel">
                <div class="list_left_bg">

                    <div class="product-cat-section">
                        <p class="cat-title-text">Category</p>

                        <div class="prod-list scrollVerical">
                            <form>

                                <div class="form-group">
                                    <label class="c-s-checkbox ">Cars
                                        <input type="checkbox">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label class="c-s-checkbox ">Electronics
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Furniture
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Jobs
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Services
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Sport & Lesuire
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Home & Garden
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Clothings
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>


                            </form>


                        </div>
                        <!-- <a href="#" class="viewMore">View More</a> -->
                    </div>
                    <!-- end category section  -->

                    <div class="product-cat-section">
                        <p class="cat-title-text">ALL BRANDS</p>
                        <div class="prod-list scrollVerical">
                            <form>
                                <div class="form-group">
                                    <label class="c-s-checkbox "> Maruti Suzuki
                                        <input type="checkbox">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label class="c-s-checkbox "> Hyundai
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Mahindra
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Tata
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Toyota
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Honda
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Chevrolet
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox "> Volkswagen
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>


                            </form>


                        </div>
                        <!-- <a href="#" class="viewMore">View More</a> -->
                    </div>
                    <!-- end brand section -->

                    <div class="product-cat-section">
                        <p class="cat-title-text">price</p>
                        <div class="prod-list">
                            <form>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Under $1,000
                                        <input type="checkbox">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label class="c-s-checkbox "> $1,000 - $5,000
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">$5,000 - $10,000
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">$10,000 - $20,000
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="c-s-checkbox ">Above $20,000
                                        <input type="checkbox" checked="checked">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                            </form>


                        </div>
                        <!-- <a href="#" class="viewMore">View More</a> -->
                    </div>
                </div>


            </div>
            <div class="col-lg-9 col-md-8 col-sm-12 searchResult">
                <div class="filter_section">
                    <div class="leftdiv">
                        <h1>Cars</h1>
                    </div>
                    <div class="rightdiv">
                        <form class="form-inline">
                            <div class="form-group">
                                <label class="sortBy">Sort by:</label>
                                <select id="inputState" class="form-control">
                                    <option selected="">Price Low to High</option>
                                    <option>Price High to Low</option>
                                    <option>Popularity</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/dell-laptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Dell inspiron 4520</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/android9Image.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 150</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> You can make excisting old things to the new </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/car.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 1674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                    Exercitationem,
                                    harum? </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/dell-laptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Dell inspiron 4520</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/alerm-clock.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 200</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Lorem ipsum dolor sit, amet consectetur</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/drill.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 14</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia,
                                    quis!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/dell-laptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Dell inspiron 4520</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/hplaptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 250</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc">Hp Specture</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/hplaptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 250</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc">Hp Specture</p>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="middle-banner">
                    <div class="m-banner-wrapper">
                        <div class="content">
                            <h2>Want to see your stuff here? </h2>
                            <p>Make some extra cash by selling things in your community. <br>
                                Go on, it's quick and easy.</p>
                        </div>
                        <button type="button" class="btn start_selling">Start Selling</button>
                    </div>
                </section>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/dell-laptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Dell inspiron 4520</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/android9Image.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 150</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> You can make excisting old things to the new </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/car.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 1674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                    Exercitationem,
                                    harum? </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/dell-laptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Dell inspiron 4520</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/alerm-clock.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 200</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Lorem ipsum dolor sit, amet consectetur</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/drill.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 14</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia,
                                    quis!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/dell-laptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 674</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc"> Dell inspiron 4520</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/hplaptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 250</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc">Hp Specture</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 latest-product">
                        <div class="product-card">
                            <div class="featured-like">
                                <button class="btn btn-outline-success">featured</button>
                                <button class="btn heart"> <i class="fa fa-heart" aria-hidden="true"></i> </button>
                            </div>
                            <div class="image-content">
                                <img src="assets/images/hplaptop.jpg">
                            </div>
                            <div class="price-section">
                                <div class="price-bar">
                                    <h2 class="price">usd 250</h2>
                                    <h3 class="times">1 Day ago</h3>
                                </div>
                                <p class="desc">Hp Specture</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn load-More"> Load More..</button>
            </div>
        </div>



    </div>
</section>

