export const environment = {
  mileSteps: [321868800, 305775360, 289681920, 273588480, 257495040, 241401600, 225308160, 209214720, 193121280, 177027840, 160934400, 144840960, 128747520, 112654080, 96560640, 80467200, 96560640, 112654080, 96560640, 80467200, 64373760, 48280320, 32186880, 16093440, 14484096, 12874752, 11265408, 9656064, 8046720, 6437376, 4828032, 3218688, 1609344, 1448409.6, 1287475.2, 1126540.8, 965606.4, 804672, 643737.6, 482803.2, 321868.8, 160934.4, 144840.96, 128747.52, 112654.08, 96560.64, 80467.2, 64373.76, 48280.32, 32186.88, 16093.44, 8046.72, 1609.34, 0],
  distanceMileSteps: [1609, 8046, 16093, 32186, 80467, 160934, 321869, 804672],
  kmSteps: [1000, 5000, 10000, 20000, 50000, 100000, 200000, 500000],
  consumableIds: ['boost_daily', 'boost_weekly', 'boost_monthly', 'reactivate_product'],
  production: true,
  webClientId: '767072480580-logd1mknpielovpp7g95p2ev09sqdek5.apps.googleusercontent.com',
  // API_BASE_URL: 'https://nodeserver.mydevfactory.com:8936/',
  API_BASE_URL: 'https://trademylist.com:8936/',
  firebaseConfig :{
    apiKey: "AIzaSyAb5r8EzswE0piYF5HJgfk-UbRLuL6VnKM",
    authDomain: "magclub-1578525388784.firebaseapp.com",
    databaseURL: "https://magclub-1578525388784.firebaseio.com",
    projectId: "magclub-1578525388784",
    storageBucket: "magclub-1578525388784.appspot.com",
    messagingSenderId: "767072480580",
    appId: "1:767072480580:web:00385f39706a7518d67b11",
    measurementId: "G-Z45H0YNBH6"
  }
};
