<!-- <button
  *ngIf="type === 'facebook'"
  (click)="share()">
    Share on Facebook
</button> -->
<i class="fa fa-facebook-square share-icon-btn" aria-hidden="true" *ngIf="type === 'facebook'" (click)="share()"></i>

<!-- <i class="fa fa-twitter-square share-icon-btn" aria-hidden="true" *ngIf="type === 'twitter'" (click)="share()"></i> -->
<!-- <button
  *ngIf="type === 'twitter'"
  (click)="share()">
    Share on Twitter
</button> -->